import React, { useEffect, useState } from "react";
import {
  LabelText,
  ButtonInput,
  TextInput,
  CheckboxInput,
} from "@emerson/dynamic-ui-public";
import "./SiteInfo.css";
const SiteInfo = (props) => {
  const {
    SRData,
    createSRorQuote,
    setSiterequirements,
    setSiteConditions,
    setAddSiteInfoDetails,
    siteConditions,
    siterequirements,
    setIsLoading,
    isLoading,
    errorMsg,
    setStepperDisable,
    setStepMemoize,
    stepMemoize,
  } = props;
  const SiteConditionsArr = [
    "Confined Spaces",
    "Heights",
    "Onshore",
    "OffShore",
    "Permit",
  ];

  const SiteRequirementsArr = [
    "Safety training",
    "Security/signed documentation",
    "Background Check",
    "Drug or alcohol testing through a third party",
    "None of the above",
  ];
  const siteConOptions = (inpArr) => {
    return inpArr.map((ipItem) => {
      return { label: ipItem, value: ipItem };
    });
  };
  useEffect(() => {
    siteConditions.length > 0 && siterequirements.length > 0
      ? setIsLoading(false)
      : setIsLoading(true);
  }, [siteConditions, siterequirements]);
  useEffect(() => {
    setStepperDisable(true);
    setStepMemoize(5);
  }, []);
  return (
    <div className="deviceServiceRequest">
      <br />
      <br />
      <LabelText text="Site Information" textClass="ddl-text--h3" />
      <br />
      <p>
        Final Step! Please select all that apply to your site.
        <br />
        <br />
      </p>
      <div className="SiteConReq">
        <CheckboxInput
          label="Site Conditions"
          required
          onChange={(a, b, c, e) => {
            setSiteConditions(e);
          }}
          options={siteConOptions(SiteConditionsArr)}
        />
        <CheckboxInput
          label="Site Requirements"
          required
          onChange={(a, b, c, e) => {
            setSiterequirements(e);
          }}
          options={siteConOptions(SiteRequirementsArr)}
        />
      </div>
      <TextInput
        label="ADDITIONAL DETAILS"
        onBlur={() => {}}
        onChange={(e) => {
          setAddSiteInfoDetails(e.target.value);
        }}
      />
      <p className="errorText">{errorMsg}</p>
      <div>
        <br />
        <br />
        <ButtonInput
          btnType=""
          label="SUBMIT"
          onClick={() => {
            createSRorQuote(SRData, "CreateQuote");
            setIsLoading(true);
          }}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};

export default SiteInfo;
