import React, { useState, useEffect } from "react";
import "./SysInfo.css";
import "../deviceDetails/DeviceDetails.jsx";
import device from "../../assets/device.png";
import system from "../../assets/system.png";
import { LabelText, ButtonInput } from "@emerson/dynamic-ui-public";
import DeviceDetails from "../deviceDetails/DeviceDetails.jsx";

const SysInfo = (props) => {
  const {
    activeInStep,
    setActiveInStep,
    serviceTypeName,
    serviceName,
    userDeviceList,
    setSelectedDeviceList,
    userSiteList,
    selectedDeviceList,
    setSelectedSite,
    selectedSite,
    setSysInfoDetails,
    sysInfoDetails,
    setSystem,
    setStepperDisable,
    setStepMemoize,
    stepMemoize,
    deviceData,
    setDeviceData,
    setReason,
    reason,
    setAdditionalReturnDetail,
    additionalReturnDetail,
    setPrefSerDate,
    prefDevDate, 
    setPrefDevDate,
  } = props;
  useEffect(() => {}, [userDeviceList]);
  useEffect(() => {
    setStepperDisable(true);
    setStepMemoize(3);
  }, []);

  return sysInfoDetails !== "" || serviceTypeName === "Return A Product" ? (
    <DeviceDetails
      activeInStep={activeInStep}
      serviceTypeName={serviceTypeName}
      setActiveInStep={setActiveInStep}
      serviceName={serviceName}
      userDeviceList={userDeviceList}
      setSelectedDeviceList={setSelectedDeviceList}
      userSiteList={userSiteList}
      selectedDeviceList={selectedDeviceList}
      setSelectedSite={setSelectedSite}
      selectedSite={selectedSite}
      sysInfoDetails={sysInfoDetails}
      setSystem={setSystem}
      setStepMemoize={setStepMemoize}
      stepMemoize={stepMemoize}
      setSysInfoDetails={setSysInfoDetails}
      setDeviceData={setDeviceData}
      deviceData={deviceData}
      setReason={setReason}
      reason={reason}
      setAdditionalReturnDetail={setAdditionalReturnDetail}
      additionalReturnDetail={additionalReturnDetail}
      setPrefSerDate={setPrefSerDate}
      prefDevDate={prefDevDate} 
      setPrefDevDate={setPrefDevDate}
    />
  ) : (
    <div className="selectFieldService">
      <br />
      <br />
      <LabelText text="System Information" textClass="ddl-text--h3" />
      <br />
      <p>
        Is it a device or a system that you need serviced.
        <br />
        <br />
      </p>
      <div className="ServiceRequestBox">
        <div className="serviceType">
          <div
            onClick={() => {
              setSysInfoDetails("Measurement Device");
            }}
          >
            <img src={device} alt="Icon" />
            Measurement Device
          </div>
          <div
            onClick={() => {
              setSysInfoDetails("Engineered Solution");
            }}
          >
            <img src={system} alt="Icon" />
            Engineered Solution
          </div>
        </div>
      </div>
    </div>
  );
};

export default SysInfo;
